.signup-background {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-box {
  height: 30rem;
  width: 50rem;
  /* border: 1px solid black; */
}

.signup-icons {
  display: flex;
  justify-content: center;
}

.signup-icons-box {
  width: 10rem;
}

.signup-icons-icon {
  width: 7rem;
  margin: 2rem;
}

.signup-icons-icon:hover {
  border: 1px solid black;
}
