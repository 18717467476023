/* ========== Expense ========== */
/* items */
.spp-ex-deleteBtn {
  width: 5%;
  font-size: 0.5rem;
}
.spp-ex-date {
  width: 20%;
}
.spp-ex-eName {
  width: 40%;
}
.spp-ex-ePrice {
  width: 35%;
}

/* total-box */
.spp-ex-total-text {
  width: 10%;
  font-weight: bold;
}
.spp-ex-total-total {
  width: 90%;
}

/* input-box */
.spp-ex-input-date {
  width: 25%;
}
.spp-ex-input-eName {
  width: 35%;
}
.spp-ex-input-ePrice {
  width: 30%;
}
.spp-ex-input-btn {
  width: 15%;
}

/* ========== FixedExpense ========== */
/* items */
.spp-fe-deleteBtn {
  width: 5%;
  font-size: 0.5rem;
}

.spp-fe-period {
  width: 25%;
}
.spp-fe-feName {
  width: 35%;
}
.spp-fe-fePrice {
  width: 15%;
}
.spp-fe-fePrice-year {
  width: 20%;
}

/* total-box */
.spp-fe-total-text {
  width: 10%;
  font-weight: bold;
}
.spp-fe-total-total {
  width: 45%;
}
.spp-fe-total-total-year {
  width: 45%;
}

/* input-box */
.spp-fe-input-startDate {
  width: 20%;
}
.spp-fe-input-endDate {
  width: 20%;
}
.spp-fe-input-feName {
  width: 25%;
}
.spp-fe-input-fePrice {
  width: 20%;
}
.spp-fe-input-btn {
  width: 15%;
}
