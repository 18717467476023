.main-background {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-title {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
}

.main-actions {
  height: 5%;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
}

.main-actions-bts {
  margin-right: 1rem;
}

.main-outlet {
  height: 80%;
  width: 90%;
}

.main-info {
  height: 5%;
}

input:focus {
  background-color: orange;
}
