/* number 화살표 제거 */
/* Hide the arrows in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrows in Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

.spp-background {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  /* background-color: pink; */
}

.spp-select {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.spp-box {
  height: 70%;
  display: flex;
}
.spp-box-space {
  height: 5%;
}

/* 각 컴포넌트의 제목 ex) 태양광, REC 발급, REC 판매, 지출, 고정 지출 */
.spp-items-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7%;
  font-size: 1.7rem;
  font-weight: bold;
}
.spp-items-title-count {
  margin-left: 1rem;
  font-size: 1.5rem;
}

/* ========== 위 ========== */
.spp-solar {
  width: 42%;
}
.spp-iRec {
  width: 16%;
}
.spp-sRec {
  width: 42%;
}

/* ========== 아래 ========== */
.spp-expense {
  width: 30%;
}
.spp-fixedExpense {
  width: 35%;
}
.spp-tax {
  width: 35%;
}

.spp-solar,
.spp-iRec,
.spp-sRec,
.spp-expense,
.spp-fixedExpense,
.spp-tax {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

/* 각 컴포넌트의 내부 상,하 박스 */
.spp-box-box1 {
  height: 70%;
}
.spp-box-box2 {
  height: 23%;
}

/* box-box1의 데이터들 타이들 */
.spp-box-box1-items-title {
  display: flex;
  padding: 0.3rem;
  font-size: 1.1rem;
  font-weight: bold;
  height: 5%;
  overflow-y: scroll; /* 안넣어주면 데이터랑 줄 안맞음 */
  background-color: lightyellow;
}
.spp-box-box1-items-title-ex {
  background-color: rgb(250, 230, 230);
}

/* box-box1의 데이터 랜더링 되는 데이터 박스 */
.spp-box-box1-items-box {
  height: 85%;
  overflow-y: scroll;
}
/* 박스 안의 내용들 */
.spp-box-box1-items {
  display: flex;
  padding: 0.3rem;
  font-size: 0.9rem;
}

.spp-box-box1-items-title div,
.spp-box-box1-items div {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* box-box2의 total box */
.spp-box-box2-total-box {
  height: 60%;
  display: flex;
  flex-direction: column;
}
.spp-box-box2-total-title-box {
  background-color: lightyellow;
  font-weight: bold;
}
.spp-box-box2-total-title-box-ex {
  background-color: rgb(250, 230, 230);
}
.spp-box-box2-total-title-box,
.spp-box-box2-total-item-box {
  display: flex;
}
.spp-box-box2-total-title-box span,
.spp-box-box2-total-item-box span {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* box-box2의 input box */
.spp-box-box2-input-box {
  height: 40%;
  display: flex;
  flex-direction: column;
  color: rgb(92, 91, 91);
}
.spp-box-box2-input-box-titles {
  height: 40%;
  display: flex;
  font-size: 0.8rem;
  font-weight: bold;
}
.spp-box-box2-input-box-inputs {
  height: 60%;
  display: flex;
  flex-direction: row;
}

/* ========== SppSelectYears ========== */
.spp-select-selectYears {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spp-select-selectYears-select {
  display: flex;
  margin-left: 1rem;
  font-size: 1.5rem;
}

.spp-select-allBtn {
  margin-left: 1rem;
  font-size: 1.2rem;
}
