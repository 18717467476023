/* ========== 태양광 ========== */
/* itemsBox */
.spp-solar-deleteBtn {
  width: 4%;
  font-size: 0.5rem;
}
.spp-solar-date {
  width: 13%;
}
.spp-solar-generation {
  width: 14%;
}
.spp-solar-smp {
  width: 10%;
}
.spp-solar-supplyPrice {
  width: 20%;
}
.spp-solar-vat {
  width: 19%;
}
.spp-solar-total {
  width: 20%;
}

/* totalsBox */
.spp-solar-total-text {
  width: 6%;
  font-weight: bold;
}
.spp-solar-total-generation {
  width: 12%;
}
.spp-solar-total-smp {
  width: 12%;
}
.spp-solar-total-supplyPrice {
  width: 25%;
}
.spp-solar-total-vat {
  width: 15%;
}
.spp-solar-total-total {
  width: 30%;
}

/* inputsBox */
.spp-solar-input-date {
  width: 20%;
}
.spp-solar-input-generation {
  width: 20%;
}
.spp-solar-input-smp {
  width: 20%;
}
.spp-solar-input-supplyPrice {
  width: 25%;
}
.spp-solar-input-btn {
  width: 15%;
}
