/* ========== IREC ========== */
/* items */
.spp-iRec-issuance {
  width: 20%;
}
.spp-iRec-fee {
  width: 40%;
}
.spp-iRec-remain {
  width: 40%;
}

/* total-box */
.spp-iRec-total-text {
  width: 15%;
  font-weight: bold;
}
.spp-iRec-total-issuance {
  width: 40%;
}
.spp-iRec-total-fee {
  width: 45%;
}

/* REC 가중치 */
.spp-iRec-recWeight-box {
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: rgb(92, 91, 91);
}
.spp-iRec-recWeight-recWeight {
  margin-left: 1rem;
}

/* =========================== */
/* ========= RestRec ========= */
.spp-restRec-box {
  height: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  font-size: 1.2rem;
  font-weight: bold;
}
.spp-restRec-calcul {
  margin-left: 1rem;
}

/* ========================== */
/* ========== SREC ========== */
/* items */
.spp-sRec-deleteBtn {
  width: 4%;
  font-size: 0.5rem;
}
.spp-sRec-date {
  width: 15%;
}
.spp-sRec-sVolume {
  width: 10%;
}
.spp-sRec-sPrice {
  width: 15%;
}
.spp-sRec-calcul {
  width: 17%;
}
.spp-sRec-vat {
  width: 14%;
}
.spp-sRec-fee {
  width: 7%;
}
.spp-sRec-total {
  width: 20%;
}

/* total-box */
.spp-sRec-total-text {
  width: 6%;
  font-weight: bold;
}
.spp-sRec-total-sVolume {
  width: 10%;
}
.spp-sRec-total-sPrice {
  width: 17%;
}
.spp-sRec-total-calcul {
  width: 20%;
}
.spp-sRec-total-vat {
  width: 15%;
}
.spp-sRec-total-fee {
  width: 10%;
}
.spp-sRec-total-total {
  width: 23%;
}

/* input-box */
.spp-sRec-input-date {
  width: 25%;
}
.spp-sRec-input-sVolume {
  width: 25%;
}
.spp-sRec-input-sPrice {
  width: 30%;
}
.spp-sRec-input-btn {
  width: 20%;
}
